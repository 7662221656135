import request from '@/utils/request'

export function getArticle(id) {
    return request({
      url: '/cms/static-page/p/' + id,
      method: 'get'
    })
  }

export function getArticleList(params) {
    return request({
        url: '/cms/article',
        method: 'get',
        params
    })
}