import request from '@/utils/request'

export function register(data) {
  return request({
    url: '/ums/auth/register',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/ums/auth/token',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/ums/auth/token',
    method: 'delete'
  })
}

/**
 * 获取Duo Plus跳转链接
 * @returns 
 */
export function getDuoPlusJumpUrl() {
  return request({
      url: `/ums/auth/duo-plus-jump`,
      method: 'get'
  })
}