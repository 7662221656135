export default {
  customProduct: {
    title: 'Contact customer service for customized exclusive products',
    confirm: 'Confirm',
    tip: 'Tips'
  },
  route: {
    Login: 'Login',
    Registration: 'Registration',
    ForgetPasswd: 'Forget Password',
    Home: 'Home',
    Price: 'Price',
    Product: 'Product',
    Commission: 'Earn Commission',
    My: 'A globally secure and reliable IP proxy service provider',
    Error: 'A globally secure and reliable IP proxy service provider',
    CashRegister: 'Cash Register',
    Article: 'Article Details',
    NotFound: 'Page Not Found',
    Unauthorized: 'Unauthorized Access',
    MyBuy: 'Buy Service',
    MyDynamicOrder: 'Dynamic Orders',
    MyStaticOrder: 'Static Orders',
    MyProxyAccount: 'Proxy Account',
    MyAccountSetting: 'Account Settings',
    MyDashboard: 'Get Proxy',
    RenewRecord: 'Renewal Record',
    MyAdResource: 'Integrated Tools',
    MyBalanceRecharge: 'Balance & Recharge'
  },
  nav: {
    promotion: 'Promotion Management',
    commissionStatistics: 'Commission Statistics',
    commissionRecords: 'Commission Records',
    home: 'Home',
    price: 'Price',
    product: 'Product',
    resourceCenter: 'Resource Center',
    login: 'Login',
    registration: 'Registration',
    introductionGuide: 'Introduction Guide',
    commonProblem: 'Common Problem',
    adsPowerAgentConfigurationTutorial: 'AdsPower Agent Configuration Tutorial',
    adsPowerDynamicProxyConfigurationTutorial: 'AdsPower Dynamic Proxy Configuration Tutorial',
    bitBrowserStaticProxyConfigurationTutorial: 'Bit Browser Static Proxy Configuration Tutorial',
    blog: 'Blog',
    useCase: 'Use Case',
    webCrawlingAndDataExtraction: 'Web Crawling and Data Extraction',
    socialMediaManagement: 'Social Media Management: How to Effectively Use IP Proxy to Improve Operation Efficiency',
    integratedTools: 'Integrated Tools',
    fingerprintBrowser: 'Fingerprint Browser',
    cloudPhone: 'Cloud Phone',
    commission: 'Promote and earn commission'
  },
  header: {
    language: {
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      en: 'English'
    },
    switchLanguage: 'switchLanguage'
  },
  footer: {
    contactus: 'Contact Us',
    email: 'Email',
    phone: 'Phone',
    product: 'Product',
    resourceService: 'Resources and Services',
    useCase: 'Use Case',
    legalDisclaimer: 'Legal Disclaimer',
    dynamicProxy: 'Dynamic Real Person Residential Agency',
    ipv4: 'Data Center IPv4 Proxy',
    ipv6: 'Data Center IPv6 Proxy',
    staticISP: 'Static residential agency',
    staticResidential: 'Static residential agency',
    staticDatacenter: 'Static datacenter agency',
    price: 'Price',
    QA: 'Q&A',
    serviceClause: 'Service And Clause',
    lawAndPrivacy: 'Law And Privacy'
  },
  global: {
    title: 'A globally secure and reliable IP proxy service provider',
    confirmLogout: 'Confirm Logout',
    loginAgain: 'Login Again',
    cancel: 'Cancel',
    error: 'Error',
    learnMore: 'Learn More',
    buyNow: 'Buy Now',
    confirm: 'Confirm',
    cancelDone: 'Cancel Success',
    edit: 'Edit',
    goBack: 'Back',
    copy: 'Copy',
    copySuccess: 'Copy Success',
    copyFailed: 'Copy Failed',
    all: 'All',
    noData: 'No Data',
    reset: 'Reset',
    startDate: 'Start Date',
    endDate: 'End Date',
    yes: 'Yes',
    no: 'No',
    startFrom: 'from',
    unlimitedShared: 'Unlimited Shared',
    unlimitedDedicated: 'Unlimited Dedicated',
    customProduct: 'Custom Product',
    contactCustomService: 'Contact customer service for customization'
  },
  user: {
    nickNameBlank: 'Nickname cannot be empty!',
    passwordBlank: 'Password cannot be empty!',
    paymentPasswordBlank: 'Payment password cannot be empty!'
  },
  auth: {
    loginBtn: 'Login',
    loginLink: 'Login',
    registrationBtn: 'Registration',
    registrationLink: 'Register Now',
    loginTitle: 'Account Login',
    registrationTitle: 'Account Registration',
    emailPlaceHolder: 'Please input the email',
    passwordPlaceHolder: 'Please input the password',
    repasswordPlaceHolder: 'Please input the rePassword',
    rememberPassword: 'Remember Password',
    forgetPassword: 'Forget Password',
    hasAccountTips: 'Do you already have an account?',
    readAgreement: 'I have read and agree',
    userAgreement: 'User Service Agreement',
    captchaPlaceHolder: 'Please input the captcha',
    sendCaptchaLink: 'Get the captcha',
    sendCaptchaAgain: 'Resend in {second} seconds',
    forgetPasswdTitle: 'Reset Password',
    newpasswordPlaceHolder: 'Please input the new password',
    confirm: 'Confirm',
    sendCaptchSuccess: 'The verification code has been sent to your registered email',
    readAgreementConfirm: 'Please read and agree to the User Service Agreement first'
  },
  home: {
    goodsTitle: 'Our products',
    advantageTitle: 'Our advantages',
    partnerTitle: 'Cooperative partner',
    useCaseTitle: 'Use Cases'
  },
  product: {
    newProduct: 'New Product Launch',
    unlimitedShared: 'Unlimited Flow（Shared）',
    unlimitedDedicated: 'Unlimited Flow（Dedicated）',
    title: 'Our Products',
    features: {
      title: 'Key Features',
      dynamic: {
        name: 'Dynamic',
        desc: 'IP addresses rotate continuously, increasing stealth and success rate'
      },
      residential: {
        name: 'Real Residential IP',
        desc: 'IPs from actual ISPs, making requests appear like regular users'
      },
      anonymity: {
        name: 'High Anonymity',
        desc: 'Hard to detect and block, suitable for bypassing geo-restrictions and accessing sensitive information'
      }
    },
    dynamic: {
      iconClass: '',
      title: 'Dynamic Real Person Residential Agency',
      description: 'The most authentic dynamic residential agent enhances the stability and credibility of the agent by frequently changing residential IP addresses, with high security and covert bypass of network restrictions and blockades',
      advantageList: [
        {
          name: 'latent ',
          description: 'Top level security concealment in the industry'
        },
        {
          name: '<div style="display:flex;flex-direction:row;align-items:flex-start;"><span style="font-size:14px">3000万</span><span style="font-size: 7px">+</span></div>',
          description: 'Compliant real residential IP proxy network'
        },
        {
          name: '99.97%',
          description: 'Normal operating hours'
        },
        {
          name: '179 pieces',
          description: 'Country Location'
        },
        {
          name: 'support',
          description: 'HTTP(S) & SOCKS5 Agreement'
        }
      ],
      introduction: {
        title: 'What is Dynamic Residential IP',
        description: 'Dynamic residential proxy is a proxy service provided through countless real residential IP addresses. Dynamic residential proxy will regularly change IP addresses and mimic the online behavior of ordinary household users, making it difficult for target websites to detect as a proxy.',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainTitle: 'main features',
        mainData: [
          {
            name: 'Dynamic nature',
            description: 'The IP address will constantly rotate, increasing concealment and success rate'
          },
          {
            name: 'Real residential IP',
            description: 'The IP provided by the actual ISP appears to be a request from an ordinary user'
          },
          {
            name: 'High anonymity',
            description: 'Difficult to detect and block, suitable for bypassing geographical restrictions and accessing sensitive information'
          }
        ]
      }
    },
    staticISP: {
      iconClass: '',
      title: 'Exclusive static ISP proxy',
      description: 'Assign active IP addresses from global Internet service providers (ISPs) to enable users to use the same residential IP for a long time as needed. This exclusive static residential agent is not only fast and highly reliable, but also effectively simulates the operational behavior of real users',
      advantageList: [
        {
          name: 'Real user simulation',
          description: 'More difficult for websites to identify as proxies, improving concealment'
        },
        {
          name: 'High anonymity',
          description: 'Reduced the risk of being monitored and blocked'
        },
        {
          name: 'Low blockade rate',
          description: 'The probability of being blocked by websites when using IP is relatively low'
        },
        {
          name: 'flexibility',
          description: 'Users can use the same IP address for a long time according to their needs'
        },
        {
          name: 'Better compatibility',
          description: 'Support more types of websites and services'
        }
      ],
      introduction: {
        title: 'Real user simulation',
        description: 'Exclusive ISP residential agents use the IP address of actual household users, which is more difficult for websites to recognize as agents and thus improves concealment.',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainData: [
          {
            name: 'High anonymity'
          },
          {
            name: 'Low blockade rate',
          },
          {
            name: 'flexibility',
          },
          {
            name: 'Better compatibility',
          }
        ]
      }
    },
    static: {
      iconClass: '',
      title: 'Static proxy',
      description: 'Static IPv4 is a fixed IP address allocated by service providers (ISPs), exclusively used by ISP residential agents to ensure speed while reducing the risk of being monitored and blocked, allowing users to continue using the same residential IP',
      advantageList: [
        {
          name: 'stability',
          description: 'Always use the same IP address to ensure connection stability'
        },
        {
          name: 'High anonymity',
          description: 'Less detected and blocked, suitable for operations that require privacy protection'
        },
        {
          name: 'Fast speed',
          description: 'Suitable for tasks such as data capture and other high bandwidth requirements'
        },
        {
          name: 'reliability',
          description: 'Avoiding speed reduction or connection issues caused by shared proxies'
        },
        {
          name: 'Controllability',
          description: 'Users can better manage and monitor their IP usage'
        }
      ],
      introduction: {
        title: 'Static proxy',
        description: 'Static IPv4 is a fixed IP address allocated by service providers (ISPs), exclusively used by ISP residential agents to ensure speed while reducing the risk of being monitored and blocked, allowing users to continue using the same residential IP',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainData: [
          {
            name: 'stability',
            description: 'Always use the same IP address to ensure connection stability'
          },
          {
            name: 'High anonymity',
            description: 'Less detected and blocked, suitable for operations that require privacy protection'
          },
          {
            name: 'Fast speed',
            description: 'Suitable for tasks such as data capture and other high bandwidth requirements'
          },
          {
            name: 'reliability',
            description: 'Avoiding speed reduction or connection issues caused by shared proxies'
          },
          {
            name: 'Controllability',
            description: 'Users can better manage and monitor their IP usage'
          }
        ]
      }
    },
    staticResidential: {
      iconClass: '',
      title: 'Static Residential Proxy',
      description: 'From global Internet service providers (ISPs), allocate active IP addresses to users, allowing users to use the same residential IP for a long time as needed. This static residential agent is not only fast and highly reliable, but also effectively simulates the operational behavior of real users',
      advantageList: [
        {
          name: 'Real user simulation',
          description: 'More difficult for websites to identify as proxies, improving concealment'
        },
        {
          name: 'High anonymity',
          description: 'Less detected and blocked, suitable for operations that require privacy protection'
        },
        {
          name: 'Low blockade rate',
          description: 'The probability of being blocked by websites when using IP is relatively low'
        },
        {
          name: 'High reliability',
          description: 'Avoiding speed reduction or connection issues caused by shared proxies'
        },
        {
          name: 'Controllability',
          description: 'Users can better manage and monitor their IP usage'
        }
      ],
      introduction: {
        title: 'Static Residential Proxy',
        description: 'From global Internet service providers (ISPs), allocate active IP addresses to users, allowing users to use the same residential IP for a long time as needed. This static residential agent is not only fast and highly reliable, but also effectively simulates the operational behavior of real users',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainData: [
          {
            name: 'Real user simulation',
            description: 'More difficult for websites to identify as proxies, improving concealment'
          },
          {
            name: 'High anonymity',
            description: 'Less detected and blocked, suitable for operations that require privacy protection'
          },
          {
            name: 'Low blockade rate',
            description: 'The probability of being blocked by websites when using IP is relatively low'
          },
          {
            name: 'High reliability',
            description: 'Avoiding speed reduction or connection issues caused by shared proxies'
          }
        ]
      },
      searchConditions: 'Search Conditions',
      isExpired: 'Expiration Status',
      notExpired: 'Not Expired',
      expired: 'Expired',
      ipLocation: 'IP Location',
      tag: 'Tag',
      ipsPlaceholder: 'IP List (Separate multiple IPs with newlines, max 500)',
      search: 'Search',
      reset: 'Reset',
      proxyInfo: 'Proxy Info',
      copyProxy: 'Copy Proxy',
      id: 'ID',
      ip: 'IP',
      totalTraffic: 'Total Traffic/GB',
      usedTraffic: 'Used Traffic/GB',
      enableStatus: 'Enable Status',
      enabled: 'Enabled',
      disabled: 'Disabled',
      onlineStatus: 'Online Status',
      online: 'Online',
      offline: 'Offline',
      autoRenew: 'Auto Renew',
      on: 'On',
      off: 'Off',
      expirationTime: 'Expiration Time',
      createTime: 'Create Time',
      refundTime: 'Refund Time',
      operations: 'Operations',
      renew: 'Renew',
      buyTraffic: 'Buy Traffic',
      batchCopyProxy: 'Batch Copy Proxy',
      selectedCount: '{count} items selected',
      noProxySelected: 'Please select at least one proxy',
      selectProxyInfo: 'Please select proxy info to copy',
      copyProxy1: 'Copy Proxy 1',
      copyProxy2: 'Copy Proxy 2',
      autoRenewChanged: 'Auto renew status changed',
      autoRenewChangeFailed: 'Failed to change auto renew status',
      renewSuccess: 'Renewal successful'
    },
    staticDatacenter: {
      iconClass: '',
      title: 'Static Datacenter Proxy',
      description: 'IP addresses from data center servers, allocated by cloud service providers or professional proxy service providers in bulk. This type of IP belongs to "Datacenter IP" (Datacenter IP)',
      advantageList: [
        {
          name: 'High bandwidth',
          description: 'Low latency, suitable for tasks requiring quick response'
        },
        {
          name: 'Low cost',
          description: 'Price is lower, suitable for large-scale deployment'
        }
      ],
      introduction: {
        title: 'Static Datacenter Proxy',
        description: 'IP addresses from data center servers, allocated by cloud service providers or professional proxy service providers in bulk. This type of IP belongs to "Datacenter IP" (Datacenter IP)',
        tips: 'The price is as low as',
        buyNow: 'Buy Now',
        mainData: [
          {
            name: 'High bandwidth',
            description: 'Low latency, suitable for tasks requiring quick response'
          },
          {
            name: 'Low cost',
            description: 'Price is lower, suitable for large-scale deployment'
          }
        ]
      },
      searchConditions: 'Search Conditions',
      isExpired: 'Expired Status',
      notExpired: 'Not Expired',
      expired: 'Expired',
      ipLocation: 'IP Location',
      tag: 'Tag',
      ipsPlaceholder: 'IP Array (For multiple IPs, separate with newlines, max 500)',
      search: 'Search',
      reset: 'Reset',
      proxyInfo: 'Proxy Info',
      copyProxy: 'Copy Proxy',
      id: 'ID',
      ip: 'IP',
      totalTraffic: 'Total Traffic/GB',
      usedTraffic: 'Used Traffic/GB',
      enableStatus: 'Enable Status',
      enabled: 'Enabled',
      disabled: 'Disabled',
      onlineStatus: 'Online Status',
      online: 'Online',
      offline: 'Offline',
      autoRenew: 'Auto Renew',
      on: 'On',
      off: 'Off',
      expirationTime: 'Expiration Time',
      createTime: 'Create Time',
      refundTime: 'Refund Time',
      operations: 'Operations',
      renew: 'Renew',
      buyTraffic: 'Buy Traffic',
      batchCopyProxy: 'Batch Copy Proxy',
      selectedCount: '{count} items selected',
      noProxySelected: 'Please select at least one proxy'
    }
  },
  advantage: {
    stability: 'Stability',
    fastspeed: 'Fast Speed',
    highanonymity: 'High Anonymity',
    reliability: 'Reliability',
    controllability: 'Controllability'
  },
  price: {
    title: 'Diversified Prices',
    outOfStock: 'Out of Stock',
    comingSoon: 'Coming Soon',
    dayPackage: 'Day Package',
    dynamic: 'Dynamic Residential Proxy',
    staticISP: 'Exclusive Static ISP Proxy',
    static: 'Static Proxy',
    staticResidential: 'Static Residential Proxy',
    staticDatacenter: 'Static Datacenter Proxy',
    advantage: [
      {
        name: 'Security and Privacy Protection'
      },
      {
        name: '300+countries with millions of agent pools'
      },
      {
        name: 'Excellent network speed'
      },
      {
        name: 'Highly scalable'
      },
      {
        name: '99.9% normal running time'
      },
      {
        name: 'Intelligent traffic management'
      }
    ]
  },
  my: {
    unitPrice: 'Unit Price',
    rechargeHistory: 'Recharge History',
    balanceRecharge: 'Balance Recharge',
    state: 'Status',
    exclusiveDiscount: 'Exclusive Discount',
    day: 'Days',
    purchaseNotice: '<p><span style="color: rgb(0, 0, 0);"><strong>Purchase Notice</strong></span></p><p>1、Before using the proxy, you need to <span style="color: rgb(225, 60, 57);"><strong>prepare overseas network</strong></span>, mainland China network cannot directly use the proxy.</p><p>2、After the proxy product is sold, it <span style="color: rgb(225, 60, 57);"><strong>does not support replacement</strong></span>, please confirm the proxy type and region information before purchase to avoid mispurchase.</p><p>3、The proxy product uses must be <span style="color: rgb(225, 60, 57);"><strong>legal and compliant</strong></span>, otherwise the consequences will be self-borne.</p>',
    staticPurchaseNotice: '<p><span style="color: rgb(0, 0, 0);"><strong>Purchase Notice</strong></span></p><p>1、Before using the proxy, you need to <span style="color: rgb(225, 60, 57);"><strong>prepare overseas network</strong></span>, mainland China network cannot directly use the proxy.</p><p>2、After the proxy product is sold, it <span style="color: rgb(225, 60, 57);"><strong>does not support replacement</strong></span>, please confirm the proxy type and region information before purchase to avoid mispurchase.</p><p>3、The proxy product uses must be <span style="color: rgb(225, 60, 57);"><strong>legal and compliant</strong></span>, otherwise the consequences will be self-borne.</p><p>4、Static IP proxy is a fixed-term package, <span style="color: rgb(225, 60, 57);"><strong>expired after expiration</strong></span>, please pay attention to the renewal time!</p>',
    originalPriceLabel: 'Original Price：',
    invitationCode: 'Invitation Code',
    payableAmount: 'Payable Amount',
    payType: 'Payment Method',
    alipay: 'Alipay',
    alipayPay: 'Alipay Payment',
    payAgreement: 'Paying is deemed to agree to',
    agreement: '《Service Agreement》',
    agreementError: 'Please read and agree to the payment service agreement!',
    pendingPay: 'Pending Payment',
    finishPay: 'Paid',
    cancelPay: 'Closed',
    avatar: 'Avatar',
    email: 'Email',
    nick: 'Nickname',
    balanceAndRecharge: 'Balance & Recharge',
    currentBalance: 'Current Balance',
    rechargeAmount: 'Recharge Amount',
    rechargeAmountPlaceholder: 'Recharge amount must be at least 1 yuan',
    rechargeSuccess: 'Recharge Successful',
    rechargeFailed: 'Recharge Failed',
    rechargeFailedTips: 'Please try again later or contact customer service',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    lastThreeMonths: 'Last Three Months',
    initialFlow: 'Initial Traffic',
    ipNum: 'IP Count',
    tag: 'Tag',
    ipLocation: 'IP Location',
    extraFlowPrice: 'Extra Traffic Price',
    buyProxy: 'Buy Proxy',
    dashboard: 'Get Proxy',
    proxyManagement: 'Order Details',
    proxySetting: 'Proxy Management',
    myAccount: 'My Account',
    accountSetting: 'Account Setting',
    authUser: 'Authorized Users',
    dynamic: 'Dynamic Orders',
    static: 'Static Orders',
    staticOrder: 'Static Orders',
    logout: 'Logout',
    leftFlow: 'Remaining Flow',
    expirationDate: 'Expiration Date',
    autoRenew: 'Auto Renew',
    autoRenewChanged: 'Auto renew setting updated',
    autoRenewChangeFailed: 'Failed to update auto renew setting',
    country: 'Country',
    stateCityOrISP: 'State-City/ISP',
    stateCity: 'State-City',
    ISP: 'ISP',
    state: 'State',
    city: 'City',
    createAuthUser: 'Create Auth User',
    recharge: 'Recharge',
    buy: 'Buy',
    selectPlaceHolder: 'Please Select',
    changeMode: 'Change Mode',
    randomIP: 'Random IP',
    delayIP: 'Delay IP',
    delayDuration: 'IP Delay Duration（1-120min）',
    hostAndPort: 'Host IP:Port',
    proxyUsername: 'Proxy Username',
    proxyPassword: 'Proxy password',
    tips: 'Due to policy reasons, our proxy service is not supported in Chinese Mainland.',
    proxyAddress1: 'Username:Password:Host IP:Port',
    proxyAddress2: 'Host IP:Port:Username:Password',
    proxyAddress3: 'Username:password@Host IP:Port',
    addBatch: 'Add Batch',
    copyList: 'Copy the proxy list',
    userPlaceHolder: 'Input the username',
    search: 'Search',
    addAuthUser: 'Add Auth User',
    username: 'Username',
    flowLimit: 'Traffic usage limit',
    status: {
      pending: 'Pending',
      suspended: 'Suspended',
      settled: 'Settled',
      closed: 'Closed',
      doing: 'Processing'
    },
    totalConsume: 'Total Consume',
    createTime: 'Create Time',
    password: 'Password',
    usernamePlaceHolder: 'Supports 6-24 characters, letters/numbers/underscores',
    usernameEmpty: 'Please input a username',
    usernameError: 'Please enter a username of 6-24 characters',
    passwordPlaceHolder: 'Password requires 6-15 characters and does not support special symbols',
    passwordEmpty: 'Please input a password',
    passwordError: 'Password requires 6-15 characters and does not support special symbols',
    flowLimitGB: 'Traffic limit（GB）',
    orderDetail: 'Order Detail',
    startAt: 'Start Date',
    endAt: 'End Date',
    orderNoPlaceHolder: 'Input the orderNo',
    orderNo: 'OrderNo',
    packageDuration: 'Package Duration',
    flow: 'Flow/GB',
    totalAmount: 'Total Amount',
    orderStatus: 'Order Status',
    paymentTime: 'Payment Time',
    actions: 'Actions',
    toPay: 'To Pay',
    proxyType: 'Proxy Type',
    orderType: 'Order Type',
    selectPackage: 'Select Package',
    renewTable: {
      ip: 'IP',
      autoRenew: 'Auto Renew',
      proxyType: 'Proxy Type',
      totalCost: 'Total Cost',
      renewDuration: 'Renewal Duration',
      flow: 'Traffic',
      renewStatus: 'Renewal Status',
      renewResult: 'Renewal Result',
      renewTime: 'Renewal Time'
    },
    staticOrderTable: {
      orderNo: 'Order No.',
      packageDuration: 'Package Duration',
      initialFlow: 'Initial Traffic',
      ipNum: 'IP Count',
      tag: 'Tag',
      ipLocation: 'IP Location',
      actions: 'Actions'
    },
    renewRecordTitle: 'Renewal Records',
    renewSuccess: 'Renewal Successful',
    renewFail0: 'Renewal Failed [System Busy]',
    renewFail1: 'Renewal Failed [Insufficient Account Balance]',
    renewFail2: 'Renewal Failed [User Disabled]',
    renewRecordError: 'Failed to get renewal records',
    autoRenewOption: 'Auto Renewal',
    autoRenewTip: 'Please ensure sufficient balance for successful auto renewal',
    yes: 'Yes',
    no: 'No',
    renew: 'Renew',
    buyTraffic: 'Buy Traffic',
    buyTrafficSuccess: 'Buy Traffic Successfully',
    totalCost: 'Total Cost',
    renewDuration: 'Renewal Duration',
    extraPricePerG: 'Extra Price Per GB',
    buyTrafficAmount: 'Buy Traffic Amount',
    loginPassword: 'Login Password',
    paymentPassword: 'Payment Password',
    paymentPasswordTip: 'Payment password must be 6 digits',
    paymentPasswordUpdateSuccess: 'Payment password updated successfully'
  },
  payment: {
    orderInfo: 'Order Information',
    orderNo: 'Order No.',
    amount: 'Payment Amount',
    orderStatus: 'Order Status',
    exchangeAmount: 'CNY Amount',
    pendingPay: 'Pending Payment',
    paySuccess: 'Payment Successful',
    cancelPay: 'Closed',
    timeout: 'Timeout',
    orderExpired: 'Order Expired',
    error: 'Error',
    qrcodeError: 'Failed to generate QR code',
    networkError: 'Network Error',
    fetchOrderError: 'Failed to fetch order information',
    orderNotFound: 'Order not found',
    createQrcodeError: 'Failed to create payment QR code',
    payUrlError: 'Failed to get payment URL',
    payError: 'Payment Failed',
    pleasePayIn: 'Please complete payment within {time}',
    scanToPay: 'Scan QR code to pay',
    zhifubaopay: 'Alipay Payment',
    weixinpay: 'WeChat Payment',
    alipay: 'Alipay',
    weixin: 'WeChat',
    balance: 'Balance',
    balancePay: 'Balance Payment',
    currentBalance: 'Current Balance',
    insufficientBalance: 'Insufficient Balance',
    goToRecharge: 'Recharge',
    noPaymentPassword: 'Payment password not set',
    setPaymentPassword: 'Set Payment Password',
    enterPaymentPassword: 'Enter 6 digits',
    confirmPaymentPassword: 'Confirm 6 digits',
    passwordMismatch: 'Passwords do not match',
    passwordSetSuccess: 'Payment password set successfully',
    passwordSetFailed: 'Failed to set payment password',
    passwordLength: 'Password must be 6 digits',
    pleaseInputPaymentPassword: 'Please enter 6 digits',
    confirmPay: 'Confirm Payment',
    paySuccessTips: 'Payment successful, redirecting...',
    zhifubaotips: 'Open Alipay [Scan]\nScan QR code to complete payment',
    weixintips: 'Open WeChat [Scan]\nScan QR code to complete payment',
    passwordWeak: 'Weak',
    passwordMedium: 'Medium',
    passwordGood: 'Good',
    passwordStrong: 'Strong',
    passwordLength: 'Password must be 6 digits',
    passwordMismatch: 'The two passwords do not match',
    passwordSetSuccess: 'Payment password set successfully',
    passwordSetFailed: 'Failed to set payment password',
    pleaseInputPaymentPassword: 'Please enter 6 digits',
    confirmPaymentPassword: 'Please confirm payment password',
    enterPaymentPassword: 'Enter payment password',
    noPaymentPassword: 'Payment password not set',
    setPaymentPassword: 'Set Payment Password'
  },
  test: '测试',
  commission: {
    banner: {
      title: 'Promote Through Your Content',
      subtitle: 'Earn up to {percent} Commission per Order',
      button: 'Start Inviting'
    },
    steps: {
      title: '4 Steps to Join Affiliate Program',
      step1: '01.Register Account',
      step2: '02.Invite Users',
      step3: '03.Setup Payment',
      step4: '04.Earn Commission'
    },
    tips: {
      title: 'Promotion Tips',
      tipPrefix: 'Tip',
      tip1: {
        title: 'Create Personalized Content',
        content: 'Create unique promotional content for different social platforms. Pay attention to promotion "safety" and "compliance", and do not promote through false information or exaggerated benefits.'
      },
      tip2: {
        title: 'Leverage Social Media Traffic',
        content: 'Promote on social platforms such as: TikTok, Instagram, Facebook, Twitter, YouTube, etc., you can promote through multiple platforms.'
      },
      tip3: {
        title: 'Interactive Communication',
        content: 'Build trust relationships through interaction with followers, making it easier to achieve conversions.'
      },
      tip4: {
        title: 'Stay Consistent',
        content: 'Maintain continuous content updates, persist in quality content output, accumulate followers in the long term, and form a virtuous cycle.'
      }
    },
    statistics: {
      title: 'Commission Statistics',
      promotionLink: 'Promotion Link',
      copyButton: 'Copy Link',
      copySuccess: 'Copy Success',
      copyFailed: 'Copy Failed',
      error: 'Failed to get commission statistics',
      exchangeAmount: 'Exchange Amount (CNY): ',
      pendingCommission: 'Pending Commission',
      suspendedCommission: 'Suspended Commission',
      finishedCommission: 'Settled Commission',
      doingCommission: 'Processing Commission',
      search: 'Search',
      reset: 'Reset',
      amount: 'Commission Amount',
      exchangeAmount: 'Settlement Amount：',
      settleTime: 'Settlement Time',
      suspendedTime: 'Suspended Time',
      createTime: 'Create Time'
    },
    settlement: {
      title: 'Settlement Records',
      submit: {
        button: 'Submit Settlement',
        title: 'Submit Settlement Application',
        payeeName: 'Payee Name',
        payeeNamePlaceholder: 'Please enter payee name',
        payeeNameRequired: 'Payee name is required',
        alipayAccount: 'Alipay Account',
        alipayAccountPlaceholder: 'Please enter Alipay account',
        alipayAccountRequired: 'Alipay account is required',
        amount: 'Settlement Amount',
        tooltip: 'The settlement amount is the total amount of pending commission',
        cancel: 'Cancel',
        confirm: 'Confirm',
        success: 'Settlement submitted successfully',
        error: 'Failed to submit settlement'
      },
      search: {
        placeholder: {
          settleBatchNo: 'Settlement Batch No.',
          handleStatus: 'Status'
        },
        dateTo: 'to',
        startDate: 'Start Date',
        endDate: 'End Date',
        submit: 'Search',
        reset: 'Reset'
      },
      status: {
        pending: 'Pending',
        suspended: 'Suspended',
        settled: 'Settled',
        closed: 'Closed',
        doing: 'Processing'
      },
      columns: {
        id: 'ID',
        settleBatchNo: 'Batch No.',
        amount: 'Amount',
        exchangeAmount: 'CNY Amount',
        handleStatus: 'Status',
        handleTime: 'Process Time',
        createTime: 'Create Time',
        updateTime: 'Update Time'
      },
      error: 'Failed to get settlement list'
    },
    orderNo: 'Order No',
    settleBatchNo: 'Settlement Batch No',
    datePicker: {
      startDate: 'Start Date',
      to: 'to',
      endDate: 'End Date'
    },
    settleStatus: 'Settlement Status',
    statusOptions: {
      pending: 'Pending',
      suspended: 'Suspended',
      settled: 'Settled',
      processing: 'Processing'
    },
    search: 'Search',
    reset: 'Reset',
    amount: 'Commission Amount',
    exchangeAmount: 'Settlement Amount',
    settleTime: 'Settlement Time',
    suspendedTime: 'Suspended Time',
    createTime: 'Create Time'
  },
  common: {
    cancel: 'Cancel'
  },
  pagination: {
    total: 'Total {total} records',
    pageSize: '{size} items/page',
    goto: 'Go to',
    page: 'Page',
    prev: 'Previous',
    next: 'Next',
    pageClassifier: 'Page',
    sizes: 'items/page'
  },
  resource: {
    officialDisclaimer: 'Official display only. For any usage issues, please consult platform customer service'
  },
  article: {
    loadFailed: 'Load Failed',
    articleDetail: 'Article Detail',
    lastUpdate: 'Last Updated: ',
    articleNotFound: 'Article not found',
    loadError: 'Failed to load article, please try again later',
    noArticleId: 'Article ID does not exist'
  },
  seo: {
    home: {
      title: 'The most cost-effective IP overseas - native residential IP more stable - massive dynamic and static IP InstaIP',
      description: 'IP-INSTAL offers global static IP proxies, Telegram anti-blocking, overseas network environment setup, affordable residential proxies, and data collection IPs',
      keywords: 'global static IP proxy,Telegram anti-blocking,overseas network setup,affordable residential proxy,data collection IP,social media multi-account,cross-border e-commerce,real residential IP proxy,dynamic vs static IP,reliable IP proxy provider,TikTok account nurturing IP proxy,Amazon anti-association static IP'
    },
    registration: {
      title: 'Register Account - IP-INSTAL Professional IP Proxy Service',
      description: 'Register an IP-INSTAL account to access high-quality global static IP proxies, Telegram anti-blocking IPs, residential proxies and more services for various application scenarios',
      keywords: 'High anonymity IP proxy, stable IP for cross-border e-commerce, cheap proxy IP, free proxy IP'
    }
  },
  structuredData: {
    organization: {
      name: 'IP-INSTAL',
      description: 'IP-INSTAL provides high-quality static residential proxies, static data center proxies, and dynamic proxy services, supporting multiple regions worldwide with stability and reliability.',
      contactType: 'Customer Service'
    }
  }
}
