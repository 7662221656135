export default {
  route: {
    Login: '登錄',
    Registration: '註冊',
    ForgetPasswd: '忘記密碼',
    Home: '首頁',
    Price: '價格',
    Product: '產品',
    Commission: '推廣賺佣金',
    My: '全球安全可靠的IP代理服務商',
    Error: '全球安全可靠的IP代理服務商',
    CashRegister: '收銀台',
    Article: '文章詳情',
    NotFound: '頁面未找到',
    Unauthorized: '未授權訪問',
    MyBuy: '購買服務',
    MyDynamicOrder: '動態訂單',
    MyStaticOrder: '靜態訂單',
    MyProxyAccount: '代理賬戶',
    MyAccountSetting: '賬戶設置',
    MyDashboard: '獲取代理',
    CommissionStatistics: '佣金統計',
    CommissionRecords: '佣金記錄',
    RenewRecord: '續期記錄',
    MyAdResource: '集成工具',
    MyBalanceRecharge: '餘額&充值'
  },
  nav: {
    home: '首頁',
    price: '價格',
    product: '產品',
    resourceCenter: '資源中心',
    login: '登錄',
    registration: '註冊',
    introductionGuide: '入門指導',
    commonProblem: '常見問題',
    adsPowerAgentConfigurationTutorial: 'AdsPower代理配寘教程',
    adsPowerDynamicProxyConfigurationTutorial: 'AdsPower動態代理配寘教程',
    bitBrowserStaticProxyConfigurationTutorial: '比特瀏覽器靜態代理配寘教程',
    blog: '博客',
    commission: '推廣賺佣金',
    promotion: '推廣管理',
    commissionStatistics: '佣金統計',
    commissionRecords: '佣金記錄',
    useCase: '使用案例',
    webCrawlingAndDataExtraction: '網頁抓取與爬取',
    socialMediaManagement: '社交媒體管理：如何有效利用IP代理提高運營效率',
    integratedTools: '集成工具',
    fingerprintBrowser: '指紋瀏覽器',
    cloudPhone: '雲手機'
  },
  header: {
    language: {
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      en: 'English'
    },
    switchLanguage: '切換語言'
  },
  footer: {
    contactus: '聯繫我們',
    email: '郵箱',
    phone: '電話',
    product: '產品',
    resourceService: '資源與服務',
    useCase: '使用案例',
    legalDisclaimer: '法律聲明',
    dynamicProxy: '動態真人住宅代理',
    ipv4: '資料中心IPv4代理',
    ipv6: '資料中心IPv6代理',
    staticISP: '靜態住宅代理',
    staticResidential: '靜態住宅代理',
    staticDatacenter: '靜態數據中心代理',
    price: '價格',
    QA: 'Q&A',
    serviceClause: '服務與條款',
    lawAndPrivacy: '法律與隱私'
  },
  global: {
    title: '全球安全可靠的IP代理服務商',
    confirmLogout: '確認退出登錄',
    loginAgain: '重新登錄',
    cancel: '取消',
    error: '錯誤',
    learnMore: '了解更多',
    buyNow: '立即購買',
    confirm: '確認',
    cancelDone: '取消成功',
    edit: '編輯',
    goBack: '返回',
    copy: '複製',
    copySuccess: '複製成功',
    copyFailed: '複製失敗',
    all: '全部',
    noData: '暫無數據',
    reset: '重置',
    startDate: '開始日期',
    endDate: '結束日期',
    yes: '是',
    no: '否',
    startFrom: '起',
    unlimitedShared: '無限共享',
    unlimitedDedicated: '無限專用',
    customProduct: '定制專屬產品',
    contactCustomService: '可聯繫客服定制哦'
  },
  user: {
    nickNameBlank: '暱稱不能為空！',
    passwordBlank: '密碼不能為空！',
  },
  auth: {
    loginBtn: '登錄',
    loginLink: '登錄',
    registrationBtn: '註冊',
    registrationLink: '立即註冊',
    loginTitle: '帳號登錄',
    registrationTitle: '帳號註冊',
    emailPlaceHolder: '請輸入郵箱',
    passwordPlaceHolder: '請輸入密碼',
    repasswordPlaceHolder: '請輸入確認密碼',
    rememberPassword: '記住密碼',
    forgetPassword: '忘記密碼',
    hasAccountTips: '已有帳號？',
    readAgreement: '我已閱讀並同意',
    userAgreement: '用戶服務協議',
    captchaPlaceHolder: '請輸入驗證碼',
    sendCaptchaLink: '獲取驗證碼',
    sendCaptchaAgain: '{second}秒後可重發',
    forgetPasswdTitle: '重置密碼',
    newpasswordPlaceHolder: '請輸入新密碼',
    confirm: '確認',
    sendCaptchSuccess: '驗證碼已發送至你的註冊郵箱',
    readAgreementConfirm: '請先閱讀並同意使用者服務協定'
  },
  home: {
    goodsTitle: '我們的產品',
    advantageTitle: '我們的優勢',
    partnerTitle: '合作夥伴',
    useCaseTitle: '使用案例'
  },
  product: {
    newProduct: '新品上線',
    unlimitedShared: '無限流量（共享）',
    unlimitedDedicated: '無限流量（独享）',
    title: '我們的產品',
    features: {
      title: '主要特點',
      dynamic: {
        name: '動態性',
        desc: 'IP地址會不斷輪換，增加隱蔽性和成功率'
      },
      residential: {
        name: '真實住宅IP',
        desc: '來自實際的ISP提供的IP，因而看起來像普通用戶的請求'
      },
      anonymity: {
        name: '高匿名性',
        desc: '難以被檢測和封鎖，適合繞過地理限制和訪問敏感信息'
      }
    },
    dynamic: {
      iconClass: '',
      title: '動態真人住宅代理',
      description: '最真實的動態住宅代理，通過高頻次更換住宅IP地址來增强代理的穩定性與可信度，高安全性和隱蔽地繞過網絡限制和封鎖',
      advantageList: [
        {
          name: '隱匿性',
          description: '業內頂尖級別的安全隱匿性'
        },
        {
          name: '<div style="display:flex;flex-direction:row;align-items:flex-start;"><span style="font-size:14px">3000万</span><span style="font-size: 7px">+</span></div>',
          description: '合規的真人住宅IP代理網絡'
        },
        {
          name: '99.97%',
          description: '正常運營時間'
        },
        {
          name: '179個',
          description: '國家位置'
        },
        {
          name: '支持',
          description: 'HTTP(S) & SOCKS5協議'
        }
      ],
      introduction: {
        title: '什麼是動態住宅IP.',
        description: '動態住宅代理是通過無數真實住宅IP地址提供的代理服務，動態住宅代理會定期更換IP地址，模仿普通家庭用戶的上網行為，因而難以被目標網站檢測到為代理。',
        tips: '價格低至',
        buyNow: '立即選購',
        mainTitle: '主要特點',
        mainData: [
          {
            name: '動態性',
            description: 'IP地址會不斷輪換，新增隱蔽性和成功率'
          },
          {
            name: '真實住宅IP',
            description: '來自實際的ISP提供的IP，因而看起來像普通用戶的請求'
          },
          {
            name: '高匿名性',
            description: '難以被檢測和封鎖，適合繞過地理限制和訪問敏感資訊'
          }
        ]
      }
    },
    staticISP: {
      iconClass: '',
      title: '獨享靜態ISP代理',
      description: '從全球互聯網服務提供者（ISP）分配活躍IP地址，使用戶能够根據需要長期使用相同的住宅IP。 這種獨享靜態住宅代理不僅速度快且高度可靠，有效類比真實用戶的操作行為',
      advantageList: [
        {
          name: '真實用戶模擬',
          description: '更難被網站識別為代理，提高隱蔽性'
        },
        {
          name: '高匿名性',
          description: '减少了被監測和封鎖的風險'
        },
        {
          name: '低封鎖率',
          description: '使用IP時被網站封鎖的概率較低'
        },
        {
          name: '靈活性',
          description: '用戶可以根據需求長期使用相同的IP'
        },
        {
          name: '更好的相容性',
          description: '支持更多類型的網站和服務'
        }
      ],
      introduction: {
        title: '真實用戶模擬',
        description: '獨享ISP住宅代理使用的是實際家庭用戶的IP地址，更難被網站識別為代理，從而提高隱蔽性。',
        tips: '價格低至',
        buyNow: '立即選購',
        mainData: [
          {
            name: '高匿名性'
          },
          {
            name: '低封鎖率',
          },
          {
            name: '靈活性',
          },
          {
            name: '更好的兼容性',
          }
        ]
      }
    },
    static: {
      iconClass: '',
      title: '靜態代理',
      description: '靜態IPv4是由服務提供者（ISP）分配的固定IP地址，獨享ISP住宅代理利用這些靜態IP，確保速度的同時降低被監測和遮罩的風險讓用戶可以持續使用相同的住宅IP',
      advantageList: [
        {
          name: '穩定性',
          description: '始終使用相同的IP地址，確保連接的穩定性'
        },
        {
          name: '高匿名性',
          description: '較少被檢測和封鎖，適合需要隱私保護的操作'
        },
        {
          name: '快速速度',
          description: '適合數據抓取和其他高頻寬需求的任務'
        },
        {
          name: '可靠性',
          description: '避免了共亯代理帶來的速度下降或連接問題'
        },
        {
          name: '可控性',
          description: '用戶可以更好地管理和監控自己的IP使用情况'
        }
      ],
      introduction: {
        title: '靜態代理',
        description: '靜態IPv4是由服務提供者（ISP）分配的固定IP地址，獨享ISP住宅代理利用這些靜態IP，確保速度的同時降低被監測和遮罩的風險讓用戶可以持續使用相同的住宅IP',
        tips: '價格低至',
        buyNow: '立即選購',
        mainData: [
          {
            name: '穩定性',
            description: '始終使用相同的IP地址確保連接的穩定性'
          },
          {
            name: '高匿名性',
            description: '較少被檢測和封鎖，適合需要隱私保護的操作'
          },
          {
            name: '快速速度',
            description: '適合數據抓取和其他高頻寬需求的任務'
          },
          {
            name: '可靠性',
            description: '避免了共亯代理帶來的速度下降或連接問題'
          },
          {
            name: '可控性',
            description: '用戶可以更好地管理和監控自己的IP使用情况'
          }
        ]
      }
    },
    staticResidential: {
      iconClass: '',
      title: '靜態真人住宅代理',
      description: '從全球互聯網服務提供商(ISP)分配活躍IP地址，使用戶能夠根據需要長期使用相同的住宅IP。這種靜態住宅代理不僅速度快且高度可靠，有效模擬真實用戶的操作行為',
      advantageList: [
        {
          name: '真實用戶模擬',
          description: '更難被網站識別為代理，提高隱蔽性'
        },
        {
          name: '高匿名性',
          description: '减少了被監測和封鎖的風險'
        },
        {
          name: '低封鎖率',
          description: '使用IP時被網站封鎖的概率較低'
        },
        {
          name: '高真實性',
          description: '更難被網站識別為代理，提高隱蔽性'
        },
        {
          name: '更好的穩定性',
          description: '更難被網站識別為代理，提高隱蔽性'
        }
      ],
      introduction: {
        title: '真實用戶模擬',
        description: 'ISP住宅代理使用的是實際家庭用戶的IP地址，更難被網站識別為代理，從而提高隱蔽性。',
        tips: '價格低至',
        buyNow: '立即選購',
        mainData: [
          {
            name: '高匿名性'
          },
          {
            name: '低封鎖率',
          },
          {
            name: '高真實性',
          },
          {
            name: '更好的穩定性',
          }
        ]
      },
      searchConditions: '搜索條件',
      isExpired: '過期狀態',
      notExpired: '未過期',
      expired: '已過期',
      ipLocation: 'IP位置',
      tag: '標籤',
      ipsPlaceholder: 'IP列表（多個IP請用換行分隔，最多500個）',
      search: '搜索',
      reset: '重置',
      proxyInfo: '代理信息',
      copyProxy: '複製代理',
      id: 'ID',
      ip: 'IP',
      totalTraffic: '總流量/GB',
      usedTraffic: '已用流量/GB',
      enableStatus: '啟用狀態',
      enabled: '已啟用',
      disabled: '已禁用',
      onlineStatus: '在線狀態',
      online: '在線',
      offline: '離線',
      autoRenew: '自動續期',
      on: '開啟',
      off: '關閉',
      expirationTime: '到期時間',
      createTime: '創建時間',
      refundTime: '退款時間',
      operations: '操作',
      renew: '續期',
      buyTraffic: '購買流量',
      selectProxyInfo: '請選擇要複製的代理信息',
      copyProxy1: '複製代理1',
      copyProxy2: '複製代理2',
      autoRenewChanged: '自動續期狀態已更改',
      autoRenewChangeFailed: '自動續期狀態更改失敗',
      renewSuccess: '續期成功',
      batchCopyProxy: '批量複製代理',
      selectedCount: '已選擇 {count} 項',
      noProxySelected: '請至少選擇一個代理',
      changeIP: '更換IP',
      changeIPConfirm: '確定要更換IP嗎？',
      changeIPSuccess: '更換IP成功',
      changeIPFailed: '更換IP失敗',
      operation: '操作'
    },
    staticDatacenter: {
      iconClass: '',
      title: '靜態數據中心代理',
      description: 'IP地址來自數據中心的服務器，由雲服務商或專業代理服務商批量分配。這類IP屬於"機房IP"（Datacenter IP）',
      advantageList: [
        {
          name: '帶寬高',
          description: '延遲低，適合需要快速響應的任務'
        },
        {
          name: '低成本',
          description: '價格較低，因數據中心可批量生成IP，適合大規模部署'
        }
      ],
      introduction: {
        title: '靜態數據中心代理',
        description: 'IP地址來自數據中心的服務器，由雲服務商或專業代理服務商批量分配。這類IP屬於"機房IP"（Datacenter IP）',
        tips: '價格低至',
        buyNow: '立即選購',
        mainData: [
          {
            name: '帶寬高',
          },
          {
            name: '低成本',
          }
        ]
      },
      searchConditions: '搜索條件',
      isExpired: '是否過期',
      notExpired: '未過期',
      expired: '已過期',
      ipLocation: 'IP位置',
      tag: '標籤',
      ipsPlaceholder: 'IP數組(查詢多個ip時,請以換行分隔,最大支持500條)',
      search: '搜索',
      reset: '重置',
      proxyInfo: '代理信息',
      copyProxy: '複製代理',
      id: 'ID',
      ip: 'IP',
      totalTraffic: '總流量/GB',
      usedTraffic: '已使用流量/GB',
      enableStatus: '啟用狀態',
      enabled: '啟用',
      disabled: '禁用',
      onlineStatus: '在線狀態',
      online: '在線',
      offline: '離線',
      autoRenew: '自動續期',
      on: '開啟',
      off: '關閉',
      expirationTime: '過期時間',
      createTime: '創建時間',
      refundTime: '退款時間',
      operations: '操作',
      renew: '續期',
      buyTraffic: '購買流量',
      autoRenewChanged: '自動續期狀態已更改',
      autoRenewChangeFailed: '自動續期狀態更改失敗',
      renewSuccess: '續期成功',
      batchCopyProxy: '批量複製代理',
      selectedCount: '已選擇 {count} 項',
      noProxySelected: '請至少選擇一個代理',
      changeIP: '更換IP',
      changeIPConfirm: '確定要更換IP嗎？',
      changeIPSuccess: '更換IP成功',
      changeIPFailed: '更換IP失敗',
      operation: '操作'
    }
  },
  advantage: {
    stability: '穩定性',
    fastspeed: '快速速度',
    highanonymity: '高匿名性',
    reliability: '可靠性',
    controllability: '可控性'
  },
  price: {
    title: '多樣化的價格',
    dynamic: '動態真人住宅代理',
    staticISP: '獨享靜態ISP代理',
    static: '靜態代理',
    staticResidential: '靜態住宅代理',
    staticDatacenter: '靜態數據中心代理',
    outOfStock: '缺貨',
    comingSoon: '即將上線',
    dayPackage: '天套餐',
    advantage: [
      {
        name: '安全與隱私保障'
      },
      {
        name: '300+國家上千萬代理池'
      },
      {
        name: '卓越的網絡速度'
      },
      {
        name: '高度可擴展性'
      },
      {
        name: '99.9%正常運行時間'
      },
      {
        name: '智能流量管理'
      }
    ]
  },
  my: {
    unitPrice: '流量單價',
    loginPassword: '登錄密碼',
    paymentPassword: '支付密碼',
    paymentPasswordTip: '支付密碼長度必須為6位',
    paymentPasswordUpdateSuccess: '支付密碼修改成功',
    exclusiveDiscount: '專屬優惠',
    initialFlow: 'IP初始流量',
    lastWeek: '最近一周',
    lastMonth: '最近一個月',
    lastThreeMonths: '最近三個月',
    ipNum: 'IP數量',
    tag: '標籤',
    ipLocation: 'IP位置',
    extraFlowPrice: '額外流量單價',
    originalPriceLabel: '原價：',
    buyProxy: '購買代理',
    dashboard: '獲取代理',
    proxyManagement: '訂單明細',
    proxySetting: '代理管理',
    myAccount: '我的賬戶',
    accountSetting: '賬戶設置',
    authUser: '授權用戶',
    dynamic: '動態訂單',
    static: '靜態訂單',
    staticOrder: '靜態訂單',
    logout: '退出登錄',
    leftFlow: '剩餘流量',
    expirationDate: '到期時間',
    autoRenew: '自動續期',
    autoRenewChanged: '自動續期設置已更新',
    autoRenewChangeFailed: '自動續期設置更新失敗',
    country: '國家',
    stateCityOrISP: '州-城市/ISP',
    stateCity: '州-城市',
    ISP: 'ISP',
    state: '州',
    city: '城市',
    createAuthUser: '創建授權用戶',
    recharge: '充值',
    buy: '購買',
    selectPlaceHolder: '請選擇',
    changeMode: '輪換模式',
    randomIP: '隨機IP',
    delayIP: '延時IP',
    delayDuration: 'IP持續時長（1-120分鐘）',
    hostAndPort: '主機IP:端口',
    proxyUsername: '代理用戶名',
    proxyPassword: '代理密碼',
    tips: '由於政策原因，本站代理服務不支持在中國大陸使用。',
    proxyAddress1: '用戶名：密碼：主機IP:端口',
    proxyAddress2: '主機IP:端口：用戶名：密碼',
    proxyAddress3: '主機IP:端口：用戶名：密碼',
    addBatch: '批量生成',
    copyList: '複製代理清單',
    userPlaceHolder: '輸入用戶名',
    search: '査詢',
    addAuthUser: '添加授權用戶',
    username: '用戶名',
    flowLimit: '流量使用上限',
    status: '狀態',
    totalConsume: '總計消耗',
    createTime: '創建時間',
    password: '密碼',
    usernamePlaceHolder: '支持6～24比特字元，字母/數位/底線',
    usernameEmpty: '請輸入用戶名',
    usernameError: '請輸入6-24比特字元的用戶名',
    passwordPlaceHolder: '密碼需要6～15字元，不支持特殊符號',
    passwordEmpty: '請輸入密碼',
    passwordError: '密碼需要6-15字元，不支持特殊符號',
    flowLimitGB: '流量上限（GB）',
    orderDetail: '訂單明細',
    startAt: '開始日期',
    endAt: '結束日期',
    orderNoPlaceHolder: '輸入訂單號',
    orderNo: '訂單號',
    packageDuration: '套餐期限',
    flow: '流量/GB',
    totalAmount: '總金額',
    orderStatus: '訂單狀態',
    paymentTime: '支付時間',
    actions: '操作',
    toPay: '去支付',
    proxyType: '代理類型',
    orderType: '訂單類型',
    selectPackage: '選擇套餐',
    renewTable: {
      ip: 'IP',
      autoRenew: '自動續期',
      proxyType: '代理類型',
      totalCost: '總花費',
      renewDuration: '續期時長',
      flow: '流量',
      renewStatus: '續期狀態',
      renewResult: '續期結果',
      renewTime: '續期時間'
    },
    staticOrderTable: {
      orderNo: '訂單號',
      packageDuration: '套餐期限',
      initialFlow: 'IP初始流量',
      ipNum: 'IP數量',
      tag: '標籤',
      ipLocation: 'IP位置',
      actions: '操作'
    },
    purchaseNotice: '<p><span style="color: rgb(0, 0, 0);"><strong>購買須知</strong></span></p><p>1、使用代理前，您需要<span style="color: rgb(225, 60, 57);"><strong>自備境外網絡</strong></span>，大陸內地網絡無法直接使用該代理。</p><p>2、代理產品售出後<span style="color: rgb(225, 60, 57);"><strong>不支持退換</strong></span>，購買前請確認代理類型及地區等資訊避免誤購。 </p><p>3、代理產品使用請注意必須<span style="color: rgb(225, 60, 57);"><strong>合法合規</strong></span>，否則後果自負。</p>',
    staticPurchaseNotice: '<p><span style="color: rgb(0, 0, 0);"><strong>購買須知</strong></span></p><p>1、使用代理前，您需要<span style="color: rgb(225, 60, 57);"><strong>自備境外網絡</strong></span>，大陸內地網絡無法直接使用該代理。</p><p>2、代理產品售出後<span style="color: rgb(225, 60, 57);"><strong>不支持退換</strong></span>，購買前請確認代理類型及地區等資訊避免誤購。</p><p>3、代理產品使用請注意必須<span style="color: rgb(225, 60, 57);"><strong>合法合規</strong></span>，否則後果自負。</p><p>4、靜態IP代理為固定期限套餐，<span style="color: rgb(225, 60, 57);"><strong>過期後無法找回</strong></span>，請注意續期時間！</p>',
    day: '天',
    payableAmount: '應付金額',
    payType: '支付方式',
    alipay: '支付寶',
    alipayPay: '支付寶支付',
    payAgreement: '支付即視為同意',
    agreement: '《服務協定》',
    agreementError: '請閱讀並同意支付服務協定！',
    pendingPay: '待支付',
    finishPay: '已支付',
    cancelPay: '已關閉',
    avatar: '頭像',
    email: '郵箱',
    nick: '昵稱',
    invitationCode: '邀請碼',
    balanceAndRecharge: '餘額&充值',
    currentBalance: '當前餘額',
    rechargeAmount: '充值金額',
    rechargeAmountPlaceholder: '充值金額至少為1元',
    rechargeHistory: '充值記錄',
    rechargeSuccess: '充值成功',
    rechargePending: '充值中',
    yuan: '元',
    balanceRecharge: '餘額充值',
    ipLocationRequired: 'IP位置為必選項',
    renewRecordError: '獲取續費記錄失敗',
    renewSuccess: '續期成功',
    renewFail0: '續期失敗[系統繁忙]',
    renewFail1: '續期失敗[用戶賬戶餘額不足]',
    renewFail2: '續期失敗[用戶被禁用]',
    autoRenewOption: '自動續期',
    autoRenewTip: '請保證餘額充足，確保自動續期成功',
    renew: '續費',
    buyTraffic: '購買流量',
    buyTrafficSuccess: '購買流量成功',
    totalCost: '總費用',
    renewDuration: '續費時長',
    extraPricePerG: '每GB額外費用',
    buyTrafficAmount: '購買流量數量',
    renewRecordTitle: '續期記錄'
  },
  payment: {
    orderInfo: '訂單信息',
    orderNo: '訂單編號',
    amount: '支付金額',
    orderStatus: '訂單狀態',
    exchangeAmount: '人民幣金額',
    pendingPay: '待支付',
    paySuccess: '支付成功',
    cancelPay: '已關閉',
    timeout: '超時提示',
    orderExpired: '訂單已過期',
    error: '錯誤提示',
    qrcodeError: '二維碼生成失敗',
    networkError: '網絡錯誤',
    fetchOrderError: '獲取訂單信息失敗',
    orderNotFound: '訂單不存在',
    createQrcodeError: '創建支付二維碼失敗',
    payUrlError: '支付鏈接獲取失敗',
    payError: '支付失敗',
    pleasePayIn: '請在{time}內完成支付',
    scanToPay: '請使用手機掃碼支付',
    zhifubaopay: '支付寶支付',
    weixinpay: '微信支付',
    alipay: '支付寶',
    weixin: '微信',
    balance: '餘額支付',
    balancePay: '餘額支付',
    currentBalance: '當前餘額',
    insufficientBalance: '餘額不足',
    goToRecharge: '去充值',
    noPaymentPassword: '您尚未設置支付密碼',
    setPaymentPassword: '設置支付密碼',
    enterPaymentPassword: '請輸入6位數字',
    confirmPaymentPassword: '請再次輸入6位數字',
    passwordMismatch: '兩次輸入的密碼不一致',
    passwordSetSuccess: '支付密碼設置成功',
    passwordSetFailed: '支付密碼設置失敗',
    passwordLength: '支付密碼必須為6位數字',
    pleaseInputPaymentPassword: '請輸入6位數字',
    confirmPay: '確認支付',
    paySuccessTips: '支付成功，正在跳轉...',
    zhifubaotips: '打開支付寶 [掃一掃]\n掃描二維碼完成支付',
    weixintips: '打開微信 [掃一掃]\n掃描二維碼完成支付',
    passwordWeak: '弱',
    passwordMedium: '中等',
    passwordGood: '良好',
    passwordStrong: '強'
  },
  test: '测试',
  commission: {
    banner: {
      title: '加入',
      subtitle: '成為我們的合作夥伴，最高可獲得{percent}的佣金',
      button: '立即開始'
    },
    steps: {
      title: '如何開始',
      step1: '註冊賬號',
      step2: '分享推廣鏈接',
      step3: '客戶完成購買',
      step4: '獲得佣金'
    },
    tips: {
      title: '推廣貼士',
      tipPrefix: '貼士',
      tip1: {
        title: '選擇合適的推廣渠道',
        content: '根據目標受眾選擇最適合的社交媒體平台或網站進行推廣'
      },
      tip2: {
        title: '製作優質的推廣內容',
        content: '創作有價值的內容，突出產品優勢和實際應用場景'
      },
      tip3: {
        title: '保持與潛在客戶互動',
        content: '及時回覆咨詢，提供專業的產品建議和支持'
      },
      tip4: {
        title: '追蹤推廣效果',
        content: '定期分析數據，優化推廣策略，提高轉化率'
      }
    },
    statistics: {
      title: '我的推廣鏈接',
      copyButton: '複製鏈接',
      copySuccess: '推廣鏈接已複製到剪貼板',
      copyFailed: '複製失敗，請手動複製',
      promotionLink: '推廣鏈接',
      pendingCommission: '待結算佣金',
      suspendedCommission: '已掛起佣金',
      finishedCommission: '已結算佣金',
      doingCommission: '處理中佣金',
      exchangeAmount: '折合人民幣：'
    },
    settlement: {
      title: '結算賬單',
      columns: {
        id: 'ID',
        settleBatchNo: '結算批次號',
        amount: '金額',
        exchangeAmount: '折合人民幣',
        handleStatus: '處理狀態',
        handleTime: '處理時間',
        createTime: '創建時間',
        updateTime: '更新時間'
      },
      status: {
        pending: '待結算',
        suspended: '已掛起',
        settled: '已結算',
        closed: '已關閉',
        doing: '處理中'
      },
      search: {
        placeholder: {
          settleBatchNo: '請輸入結算批次號',
          handleStatus: '請選擇處理狀態'
        },
        dateRange: '時間範圍',
        dateTo: '至',
        startDate: '開始日期',
        endDate: '結束日期',
        submit: '查詢',
        reset: '重置'
      },
      submit: {
        button: '提交結算',
        title: '提交結算申請',
        payeeName: '收款人姓名',
        payeeNamePlaceholder: '請輸入收款人姓名',
        payeeNameRequired: '請輸入收款人姓名',
        alipayAccount: '支付寶賬號',
        alipayAccountPlaceholder: '請輸入支付寶賬號',
        alipayAccountRequired: '請輸入支付寶賬號',
        amount: '可結算金額',
        cancel: '取消',
        confirm: '提交',
        success: '提交結算申請成功',
        error: '提交結算申請失敗',
        tooltip: '一般需要一個工作日左右處理結算申請，請耐心等待！'
      },
      pagination: {
        total: '共 {total} 條',
        pageSize: '每頁 {size} 條'
      },
      loading: '加載中...',
      error: '獲取結算列表失敗'
    },
    orderNo: '訂單編號',
    settleBatchNo: '結算批次號',
    datePicker: {
      startDate: '開始日期',
      to: '至',
      endDate: '結束日期'
    },
    settleStatus: '結算狀態',
    statusOptions: {
      pending: '待結算',
      suspended: '已掛起',
      settled: '已結算',
      processing: '結算處理中'
    },
    search: '搜索',
    reset: '重置',
    amount: '佣金金額',
    exchangeAmount: '結算金額',
    settleTime: '結算時間',
    suspendedTime: '暫停時間',
    createTime: '創建時間'
  },
  common: {
    cancel: '取消'
  },
  resource: {
    officialDisclaimer: '官方僅展示，如在使用過程中存在使用問題請諮詢平台方客服'
  },
  article: {
    loadFailed: '加載失敗',
    articleDetail: '文章詳情',
    lastUpdate: '最後更新：',
    articleNotFound: '文章未找到',
    loadError: '加載文章失敗，請稍後重試',
    noArticleId: '文章ID不存在'
  },
  seo: {
    home: {
      title: '海外最具性價比IP-原生住宅IP更穩定-海量動態靜態IP-InstaIp',
      description: 'IP-INSTAL提供全球靜態IP代理、TG防封、海外網絡環境搭建、便宜的住宅代理、爬蟲數據採集IP等服務',
      keywords: '全球靜態IP代理,TG防封,海外網絡環境設置,便宜的住宅代理,爬蟲數據採集IP,社媒多賬號,跨境電商,真實住宅IP代理,動態IP和靜態IP的區別,IP代理哪家靠譜,TikTok批量養號IP代理,亞馬遜防關聯靜態IP'
    },
    registration: {
      title: '註冊賬戶 - IP-INSTAL 專業IP代理服務',
      description: '註冊 IP-INSTAL 賬戶，獲取高質量的全球靜態IP代理、TG防封IP、住宅代理等服務，支持多種應用場景',
      keywords: '高匿名名IP代理,跨境電商穩定ip,便宜的代理ip,免費代理IP'
    }
  },
  structuredData: {
    organization: {
      name: 'IP-INSTAL',
      description: 'IP-INSTAL提供高質量的靜態住宅代理、靜態數據中心代理和動態代理服務，支持全球多個地區，穩定可靠。',
      contactType: '客戶服務'
    }
  }
}
