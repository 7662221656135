<template>
  <div>
    <!-- QQ客服 -->
    <div class="service-item qq-service" 
         @mouseenter="showQQQR = true"
         @mouseleave="showQQQR = false">
      <div class="service-button">
        <div class="icon-container">
          <div class="service-icon">
            <svg t="1705288648576" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4286">
              <path d="M824.8 613.2c-16-51.4-34.4-94.6-62.7-165.3C766.5 262.2 689.3 112 511.5 112 331.7 112 256.2 265.2 261 447.9c-28.4 70.8-46.7 113.7-62.7 165.3-34 109.5-23 154.8-14.6 155.8 18 2.2 70.1-82.4 70.1-82.4 0 49 25.2 112.9 79.8 159-26.4 8.1-85.7 29.9-71.6 53.8 11.4 19.3 196.2 12.3 249.5 6.3 53.3 6 238.1 13 249.5-6.3 14.1-23.8-45.3-45.7-71.6-53.8 54.6-46.2 79.8-110.1 79.8-159 0 0 52.1 84.6 70.1 82.4 8.5-1.1 19.5-46.4-14.5-155.8" p-id="4287" fill="#ffffff"></path>
            </svg>
          </div>
        </div>
      </div>
      
      <!-- QQ二维码 -->
      <div v-show="showQQQR" class="qr-popup">
        <img src="@/assets/images/qq-qrcode.png" alt="QQ客服二维码" />
        <p>扫码添加QQ客服</p>
      </div>
    </div>

    <!-- Telegram客服 -->
    <div class="service-item telegram-service" 
         @mouseenter="showTGQR = true"
         @mouseleave="showTGQR = false">
      <div class="service-button">
        <div class="icon-container">
          <div class="service-icon">
            <svg t="1705288648576" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4286">
              <path d="M417.28 795.733333l11.946667-180.906666 329.386666-297.813334c14.506667-13.226667-3.413333-19.626667-22.186666-8.106666L330.24 548.693333l-177.493333-55.466666c-38.4-10.666667-38.4-38.4 8.533333-57.173334l690.346667-266.24c31.573333-14.506667 62.293333 7.68 49.493333 57.173334l-117.76 554.666666c-8.106667 40.106667-32 49.493333-64 31.573334L533.333333 684.373333l-84.48 81.493334c-9.813333 9.813333-17.92 17.92-31.573333 17.92z" p-id="4287" fill="#ffffff"></path>
            </svg>
          </div>
        </div>
      </div>
      
      <!-- Telegram二维码 -->
      <div v-show="showTGQR" class="qr-popup">
        <img src="@/assets/images/tg-qrcode.png" alt="Telegram客服二维码" />
        <p>扫码添加Telegram客服</p>
      </div>
    </div>

    <!-- Tawk客服 -->
    <div id="tawk-container" class="service-item tawk-service">
      <div v-if="!tawkLoaded" class="service-button tawk-button">
        <div class="icon-container">
          <div class="service-icon">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H5.17L4 17.17V4H20V16Z" fill="#ffffff"/>
              <path d="M7 9H17V11H7V9ZM7 12H14V14H7V12ZM7 6H17V8H7V6Z" fill="#ffffff"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerService',
  data() {
    return {
      showQQQR: false,
      showTGQR: false,
      tawkLoaded: false
    }
  },
  mounted() {
    this.initTawkTo();
    // 监听Tawk.to加载状态
    const checkTawk = setInterval(() => {
      if (window.Tawk_API && window.Tawk_API.getStatus) {
        this.tawkLoaded = true;
        clearInterval(checkTawk);
      }
    }, 500);

    // 5秒后如果还没加载成功，停止检查
    setTimeout(() => {
      clearInterval(checkTawk);
    }, 5000);
  },
  methods: {
    initTawkTo() {
      if (window.Tawk_API) return;
      
      var Tawk_API = window.Tawk_API || {};
      var Tawk_LoadStart = new Date();
      
      (function(){
        var s1 = document.createElement("script");
        var s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/677966f8af5bfec1dbe691d6/1igp3kfcf';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();

      Tawk_API.onLoad = () => {
        // 设置基础访客信息
        Tawk_API.setAttributes({
          'name': this.$store.state.user.user ? this.$store.state.user.user.nickName : '访客',
          'email': this.$store.state.user.user ? this.$store.state.user.user.email : '',
          'id': this.$store.state.user.user ? this.$store.state.user.user.id : '',
          'hash': this.$store.state.user.token
        }, (error) => {
          if (error) console.warn('TawkTo setAttributes error:', error);
        });
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.service-item {
  position: fixed;
  right: 20px;
  z-index: 999;
}

.qq-service {
  bottom: 240px;
}

.telegram-service {
  bottom: 180px;
}

.tawk-service {
  bottom: 60px;
}

.service-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.qq-service .service-button {
  background: #12B7F5;
}

.telegram-service .service-button {
  background: #0088cc;
}

.tawk-button {
  background: #03a84e;
}

.tawk-button:hover {
  background: #038f43;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.service-icon {
  width: 24px;
  height: 24px;
}

.icon {
  width: 100%;
  height: 100%;
}

.service-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.qq-service .service-button:hover {
  background: #0da2d9;
}

.telegram-service .service-button:hover {
  background: #0077b3;
}

.qr-popup {
  position: absolute;
  top: 50%;
  right: 54px;
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 160px;
  z-index: 1000;
  transform: translateY(-50%);
  animation: popIn 0.3s ease;
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: translateY(-50%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

.qr-popup img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.qr-popup p {
  margin: 12px 0 0;
  color: #666;
  font-size: 13px;
  line-height: 1.4;
}

.qr-popup::after {
  content: '';
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  filter: drop-shadow(2px 0 2px rgba(0, 0, 0, 0.05));
}

#tawk-container {
  ::v-deep {
    .tawk-min-container {
      right: 20px !important;
      bottom: 60px !important;
    }
  }
}
</style> 