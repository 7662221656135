import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import i18n from './lang'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/registration'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title ? i18n.t('route.' + to.meta.title) : '')

  // determine whether the user has logged in
  const hasToken = getToken()

  // 处理白名单页面
  if (whiteList.includes(to.path)) {
    if (hasToken) {
      next({ name: 'My', replace: true })
    } else {
      next()
    }
    NProgress.done()
    return
  }

  // 处理需要认证的页面
  if (to.meta.auth) {
    if (!hasToken) {
      next({
        name: 'Login',
        query: { redirect: to.path },
        replace: true
      })
      NProgress.done()
      return
    }

    // 已登录但需要获取用户信息
    if (!store.state.user.user) {
      try {
        await store.dispatch('user/getInfo')
        // 对于佣金相关路由，使用replace而不是push
        if (to.path.includes('commission-')) {
          next({ ...to, replace: true })
        } else {
          next()
        }
      } catch (error) {
        await store.dispatch('user/resetToken')
        Message.error(error || '验证失败，请重新登录')
        next({
          name: 'Login',
          query: { redirect: to.path },
          replace: true
        })
        NProgress.done()
      }
      return
    }
  }

  // 其他情况直接通过
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
