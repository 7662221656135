<template>
  <div class="top-ad" v-if="adData && !$store.getters.hasLogin">
    <el-image 
      :src="adData.url || adData.fileName" 
      fit="cover"
      class="ad-image"
      @click="handleAdClick(adData.link)"
    />
  </div>
</template>

<script>
import { getResourceSectionInfo } from '@/api/resourceSectionApi'

export default {
  name: 'TopAd',
  data() {
    return {
      adData: null
    }
  },
  mounted() {
    this.fetchAdData()
  },
  methods: {
    async fetchAdData() {
      try {
        const response = await getResourceSectionInfo('topAd')
        console.log('Top ad data:', response.data[0])
        if (response.data || response.data.length > 0) {
          this.adData = response.data[0]
          console.log('Top ad data:', this.adData)
        }
      } catch (error) {
        console.error('Failed to fetch top ad data:', error)
      }
    },
    handleAdClick(link) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-ad {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  
  .ad-image {
    width: 100%;
    height: 60px;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .top-ad {
    .ad-image {
      height: 40px;
    }
  }
}
</style>