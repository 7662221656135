<script>
export default {
    name: 'StructuredData',
    props: {
        type: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        }
    },
    watch: {
        data: {
            handler() {
                this.updateStructuredData();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        updateStructuredData() {
            // 移除现有的结构化数据
            const existingScripts = document.querySelectorAll('script[data-structured-data]');
            existingScripts.forEach(script => script.remove());
            
            // 创建新的结构化数据脚本
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.setAttribute('data-structured-data', this.type);
            script.textContent = JSON.stringify(this.data);
            document.head.appendChild(script);
            
            console.log('结构化数据已更新:', this.type);
        }
    },
    render() {
        return null;
    }
}
</script>