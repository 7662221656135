import { login, logout } from '@/api/authApi'
import { getInfo } from '@/api/userApi'
import { getToken, setToken, removeToken } from '@/utils/auth'
import * as mutationTypes from '../mutation-type.js'
import Cookies from 'js-cookie'

const state = {
  token: getToken(),
  user: Cookies.get('IPInstal-Portal-u') ? JSON.parse(Cookies.get('IPInstal-Portal-u')) : null
}

const mutations = {
  [mutationTypes.SET_TOKEN]: (state, token) => {
    state.token = token
    setToken(token)
  },
  [mutationTypes.SET_USER_INFO]: (state, user) => {
    state.user = user
    Cookies.set('IPInstal-Portal-u', JSON.stringify(user))
  },
  [mutationTypes.LOGOUT]: (state) => {
    state.user = null
    removeToken()
    Cookies.remove('IPInstal-Portal-u')
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit(mutationTypes.SET_TOKEN, data.tokenHead + data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response
        commit(mutationTypes.SET_USER_INFO, data)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit(mutationTypes.LOGOUT)
        commit(mutationTypes.SET_TOKEN, '')
        Cookies.remove('source')
        window.location.href = '/login'
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // reset token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit(mutationTypes.LOGOUT)
      commit(mutationTypes.SET_TOKEN, '')
      window.location.href = '/login'
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
