import request from '@/utils/request'

/**
 * 获取资源位详情
 * @param {string} showAt 显示位置
 * @returns {Promise}
 */
export function getResourceSectionInfo(showAt) {
    return request({
        url: `/cms/resource-section/${showAt}`,
        method: 'get'
    })
}