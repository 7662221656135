import request from '@/utils/request'

export function getInfo() {
  return request({
    url: '/ums/user/info',
    method: 'get'
  })
}

export function updateNickName(data) {
  return request({
    url: '/ums/user/nickName',
    method: 'put',
    data
  })
}

export function updatePasswd(data) {
  return request({
    url: '/ums/user/password',
    method: 'put',
    data
  })
}

export function resetPasswd(data) {
  return request({
    url: '/ums/user/reset-password',
    method: 'post',
    data
  })
}

export function resetPaymentPasswd(data) {
  return request({
    url: '/ums/user/payment-password',
    method: 'put',
    data
  })
}