<template>
  <div id="app">
    <seo-meta :canonical-url="$route.path" />
    <structured-data :type="'Organization'" :data="getOrganizationData()" />
    <router-view />
    <customer-service />
  </div>
</template>

<script>
import SeoMeta from '@/components/SeoMeta.vue'
import StructuredData from '@/components/StructuredData.vue'
import CustomerService from '@/components/CustomerService.vue'
import * as mutationTypes from '@/store/mutation-type.js'
import analyticsConfig from '@/config/analytics'
import { destroyTawk } from './utils/tawk'

export default {
  name: 'App',
  components: {
    CustomerService,
    SeoMeta,
    StructuredData
  },
  methods: {
    getOrganizationData() {
      // 使用 i18n 获取多语言结构化数据
      return {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": this.$t('structuredData.organization.name'),
        "url": "https://www.instaip.net",
        "logo": "https://www.instaip.net/img/logo.png",
        "description": this.$t('structuredData.organization.description'),
        "contactPoint": {
          "@type": "ContactPoint",
          "email": "ipinsta@163.com",
          "contactType": this.$t('structuredData.organization.contactType')
        }
      };
    }
  },
  // ... 其他代码保持不变
  watch: {
    // 监听语言变化，更新结构化数据
    '$i18n.locale'() {
      // 强制重新渲染组件以更新结构化数据
      this.$forceUpdate();
    }
  },
  mounted() {
    // 添加百度统计
    window._hmt = window._hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = `https://hm.baidu.com/hm.js?${analyticsConfig.baidu.siteId}`;
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();

    // 路由变化时自动发送页面统计
    this.$router.afterEach((to) => {
      if (window._hmt) {
        window._hmt.push(['_trackPageview', to.fullPath]);
      }
    });

    // 获取查询字符串部分，包括开头的 '?'
    let searchParams = window.location.search;
    // 去除 '?'，并将查询字符串分割成键值对数组
    let paramsArray = searchParams.slice(1).split('&');
    let params = {};
    // 遍历数组，将键值对存入对象
    for (let i = 0; i < paramsArray.length; i++) {
      let pair = paramsArray[i].split('=');
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      params[key] = value;
    }
    if (params.hasOwnProperty('source')) {
      this.$store.commit('app/' + mutationTypes.SET_SOURCE, params.source)
    }
    
    if (params.hasOwnProperty('invitationCode')) {
      this.$store.commit('app/' + mutationTypes.SET_INVITATION_CODE, params.invitationCode)
    }
  },
  beforeDestroy() {
    // 清理Tawk.to
    destroyTawk()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}
</style>
