import Cookies from 'js-cookie'
import { getLanguage } from '@/lang/index'
import * as mutationTypes from '../mutation-type.js'

const state = {
    lang: getLanguage(),
    source: Cookies.get('source'),
    invitationCode: Cookies.get('invitationCode')
}

const mutations = {
    [mutationTypes.SET_LANGUAGE]: (state, data) => {
        state.lang = data
        Cookies.set('lang', data)
    },
    [mutationTypes.SET_SOURCE]: (state, data) => {
        state.source = data
        Cookies.set('source', data)
    },
    [mutationTypes.SET_INVITATION_CODE]: (state, data) => {
        state.invitationCode = data
        Cookies.set('invitationCode', data)
    }
}

const actions = {
    setLanguage({ commit }, language) {
        commit(mutationTypes.SET_LANGUAGE, language)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}