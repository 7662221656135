export function initTawk() {
  // 检查是否需要加载Tawk.to
  if (process.env.NODE_ENV === 'development') {
    console.log('Tawk.to is disabled in development mode');
    return;
  }

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();

  window.addEventListener('load', function() {
    try {
      var s1 = document.createElement("script");
      s1.async = true;
      s1.src = 'https://embed.tawk.to/677966f8af5bfec1dbe691d6/1igp3kfcf'; // 更新为正确的 Tawk ID
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      
      s1.onerror = function(error) {
        console.error('Error loading Tawk.to widget:', error);
      };

      document.head.appendChild(s1);
    } catch (error) {
      console.error('Error initializing Tawk.to:', error);
    }
  });
}

export function destroyTawk() {
  try {
    if (window.Tawk_API && typeof window.Tawk_API.destroy === 'function') {
      window.Tawk_API.destroy();
    }
  } catch (error) {
    console.error('Error destroying Tawk.to:', error);
  }
} 