import Vue from 'vue'
import '@/icons'
import 'normalize.css/normalize.css'
import './permission'
import '@/styles/index.scss'
import '@/styles/setting/element-variables.scss'
import i18n from './lang'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import Cookies from 'js-cookie'
import VueClipboard from 'vue-clipboard2'
import config from '@/core/config/index'
import '@/filters/index.js'
import * as mutationTypes from '@/store/mutation-type'
import { initTawk } from './utils/tawk'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW'
import enLocale from 'element-ui/lib/locale/lang/en'
import VueMeta from 'vue-meta';

Vue.use(VueClipboard)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false
Vue.prototype.$config = config
Vue.prototype.$mutationTypes = mutationTypes

// 根据当前语言选择对应的语言包
const getLocale = () => {
  const lang = i18n.locale
  switch (lang) {
    case 'zh-CN':
      return zhLocale
    case 'zh-TW':
      return zhTWLocale
    case 'en':
      return enLocale
    default:
      return zhLocale
  }
}

Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  i18n: (key, value) => i18n.t(key, value),
  locale: getLocale()
})

// 监听语言变化，动态更新 Element UI 的语言
i18n.afterChange = (newLang) => {
  Element.locale(getLocale())
}

Vue.directive('auth-required', {
  inserted(el, binding, vnode) {
    if (!store.getters.hasLogin) {
      router.push({ name: 'Login' })
    }
  }
})

// 初始化Tawk.to
initTawk()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')