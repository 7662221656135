<script>
export default {
    name: 'SeoMeta',
    props: {
        title: {
            type: String,
            default: 'IP-INSTAL - 专业IP代理服务提供商'
        },
        description: {
            type: String,
            default: 'IP-INSTAL提供高质量的静态住宅代理、静态数据中心代理和动态代理服务，支持全球多个地区，稳定可靠。'
        },
        keywords: {
            type: String,
            default: 'IP代理,住宅代理,数据中心代理,动态代理,静态IP'
        },
        noIndex: {
            type: Boolean,
            default: false
        },
        canonicalUrl: {
            type: String,
            default: ''
        }
    },
    created() {
        this.updateSeoMeta();
    },
    watch: {
        // 监听属性变化，动态更新 SEO 元数据
        title() {
            this.updateSeoMeta();
        },
        description() {
            this.updateSeoMeta();
        },
        keywords() {
            this.updateSeoMeta();
        },
        noIndex() {
            this.updateSeoMeta();
        },
        canonicalUrl() {
            this.updateSeoMeta();
        },
        // 监听路由变化
        '$route'() {
            this.updateSeoMeta();
        },
        // 监听语言变化
        '$i18n.locale'() {
            this.updateSeoMeta();
        }
    },
    methods: {
        updateSeoMeta() {
            document.title = this.title;

            // 更新meta标签
            this.updateMeta('description', this.description);
            this.updateMeta('keywords', this.keywords);

            // 处理noindex标记
            if (this.noIndex) {
                this.updateMeta('robots', 'noindex, nofollow');
            } else {
                this.updateMeta('robots', 'index, follow');
            }

            // 添加规范链接
            this.updateCanonical();

            // 添加多语言支持
            this.addHreflangTags();

            // 添加调试日志
            console.log('SEO Meta 已更新:', {
                title: document.title,
                description: document.querySelector('meta[name="description"]')?.getAttribute('content'),
                canonical: document.querySelector('link[rel="canonical"]')?.getAttribute('href'),
                hreflangCount: document.querySelectorAll('link[rel="alternate"][hreflang]').length
            });
        },
        updateMeta(name, content) {
            // 查找现有标签
            let meta = document.querySelector(`meta[name="${name}"]`);

            // 如果不存在则创建
            if (!meta) {
                meta = document.createElement('meta');
                meta.setAttribute('name', name);
                document.head.appendChild(meta);
            }

            // 设置内容
            meta.setAttribute('content', content);
        },
        updateCanonical() {
            // 构建规范链接
            const baseUrl = 'https://www.instaip.net';
            const path = this.canonicalUrl || window.location.pathname;
            const canonical = path.startsWith('http') ? path : `${baseUrl}${path}`;

            // 查找现有标签
            let link = document.querySelector('link[rel="canonical"]');

            // 如果不存在则创建
            if (!link) {
                link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                document.head.appendChild(link);
            }

            // 设置href
            link.setAttribute('href', canonical);
        },
        addHreflangTags() {
            // 移除现有的 hreflang 标签
            document.querySelectorAll('link[rel="alternate"][hreflang]').forEach(el => el.remove());

            const baseUrl = 'https://www.instaip.net';
            const path = this.canonicalUrl || window.location.pathname;

            // 支持的语言列表
            const languages = [{
                    code: 'zh-CN',
                    path: path
                },
                {
                    code: 'zh-TW',
                    path: path
                },
                {
                    code: 'en',
                    path: path
                }
            ];

            // 为每种语言创建 hreflang 标签
            languages.forEach(lang => {
                const link = document.createElement('link');
                link.setAttribute('rel', 'alternate');
                link.setAttribute('hreflang', lang.code);
                link.setAttribute('href', `${baseUrl}${lang.path}`);
                document.head.appendChild(link);
            });

            // 添加 x-default hreflang 标签
            const defaultLink = document.createElement('link');
            defaultLink.setAttribute('rel', 'alternate');
            defaultLink.setAttribute('hreflang', 'x-default');
            defaultLink.setAttribute('href', `${baseUrl}${path}`);
            document.head.appendChild(defaultLink);
        }
    },
    render() {
        return null;
    }
}
</script>
